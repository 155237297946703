import 'animate.css/animate.css';

import {  Link } from 'gatsby';
// import slide1 from "../images/images/slide1.png"
// import downarrow from "../images/images/downarrow.svg"
import React from 'react';

import assurity from '../images/assurity.png';
import lglogo from '../images/legal-general.png';
import nationwidelogo from '../images/nationwidelogo.png';
import northamerica from '../images/north-america.png';
import aegis from '../images/partner/aegis.png';
import amig from '../images/partner/AMIG-Logo.png';
import annex from '../images/partner/annex_logo.png';
import bamboo from '../images/partner/bamboo.png';
import delos from '../images/partner/Delos_Logo_S.png';
import foremost from '../images/partner/foremost.png';
import hippo from '../images/partner/hippo.png';
import kw from '../images/partner/KW-Specialty-Logo.jpg';
import openly from '../images/partner/openly.png';
import pacific from '../images/partner/pacific.png';
import ses from '../images/partner/SES logo.png';
import travelers from '../images/partner/travelers-logo.svg';
import prudential from '../images/prudential.png';
// import safecologo from '../images/safecohome.png';
import sbli1 from '../images/SBLI Logo.png';
import stillwater1 from '../images/stillwater-logo.png';
import transamerica from '../images/transamerica.png';
import wnslogo from '../images/wnslogo.svg';


const PartnerLogo = () => {

	return(
		<>
			<div className="workslide-box">
				<div className="container common-container-space">
					<div className="row">
						<div className="workslide-box-text">
							<h3 className="box-head pb-4 text-center">Bubble only works with top-rated insurance carriers.</h3>
						</div>
					</div>

					<div className="brandlogo-list">
						<div className="row align-items-center">
							{/* <div className="col-6 col-md-4 mx-auto text-center">
                <Link to="/next-by-pacific-life/">
                  <img src={paclifenext} className="pacLifeImage" loading='lazy' alt="Next By PacLife" />
                </Link>
              </div> */}

							<div className="col-6 col-md-4 text-center">
								<Link href="https://www.lemonade.com/" target="_blank">
									<img src="https://app.getmybubble.co/v2/assets/Images/images/lemonade.svg" className="sbliImage sblimobimg" alt="Branch" />
								</Link>
							</div>

							<div className="col-6 col-md-4 text-center">
								<Link href="https://www.ourbranch.com/"  target="_blank">
									<img src="https://cdn.getmybubble.com/images/new_home_reco/menuicons/branch.min.svg" className="sbliImage sblimobimg" alt="Branch" />
								</Link>
							</div>

							<div className="col-6 col-md-4 text-center">
								<Link to="https://www.kellyklee.com/"  target="_blank">
									<img src="https://cdn.getmybubble.com/images/new_home_reco/partner_icons/kelly.webp" className="sbliImage sblimobimg" alt="Kelly Klee" />
								</Link>
							</div>

							<div className="col-6 col-md-4 text-center">
								<Link to="/SBLI/">
									<img src={sbli1} className="sbliImage sblimobimg" alt="SBLI" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center desklogo">
								<Link to="/stillwater/">
									<img src={stillwater1} className="stillWater" loading='lazy' alt="Stillwater" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link to="/homeowners-insurance/nationwide/">
									<img src={nationwidelogo} className="Nationwide" loading='lazy' alt="Nationwide" />
								</Link>
							</div>

							{/* <div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link to="/homeowners-insurance/safeco/">
									<img src={safecologo} className="Safeco" loading='lazy' alt="Safeco" />
								</Link>
							</div> */}

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link to="https://cdn.back9ins.com/product-guides/105-LAA2233.pdf" target="_blank">
									<img src={lglogo} className="Legal & General" loading='lazy' alt="Legal & General" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link to="/wns/">
									<img src={wnslogo} className="W&S" loading='lazy' alt="W&S" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link to="https://cdn.back9ins.com/product-guides/665-1010826-00002_Term%2BEssential%2BTerm%2BElite%2BConsumer%2BBrochure%2B(1).pdf" target="_blank">
									<img src={prudential} className="Prudential Financial" loading='lazy' alt="Prudential Financial" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link to="https://cdn.back9ins.com/product-guides/550-608NM-1%2BADDvantage%2BTerm%2BConsumer%2BGuide.pdf" target="_blank">
									<img src={northamerica} className="North American" loading='lazy' alt="North American" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link to="https://assurity.dmplocal.com/dsc/collateral/Term_Life_Consumer_Brochure.pdf?d03930b82852e26633e6bac5638c4b2c" target="_blank">
									<img src={assurity} className="Assurity" loading='lazy' alt="Assurity" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link to="https://cdn.back9ins.com/product-guides/441-TransamericaTrendsetterLBBrochure.pdf" target="_blank">
									<img src={transamerica} className="Transamerica" loading='lazy' alt="Transamerica" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link target="_blank">
									<img src={bamboo} className="Bamboo" loading='lazy' alt="Bamboo" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link target="_blank">
									<img src={hippo} className="Hippo" loading='lazy' alt="Hippo" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link target="_blank">
									<img src={aegis} className="AEGIS" loading='lazy' alt="AEGIS" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link to="https://www.foremost.com" target="_blank">
									<img src={foremost} className="Foremost" loading='lazy' alt="Foremost" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link target="_blank">
									<img src={openly} className="Openly" loading='lazy' alt="Openly" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link to="https://www.pacificspecialty.com" target="_blank">
									<img src={pacific} className="Pacific" loading='lazy' alt="Pacific" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link target="_blank">
									<img src="https://cdn.getmybubble.com/corporate-sites/AMIG-Logo.png" className="Pacific" loading='lazy' alt="AMIG" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link target="_blank">
									<img src="https://cdn.getmybubble.com/corporate-sites/annex_logo.png" className="Pacific" loading='lazy' alt="Annex" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link target="_blank">
									<img src="https://cdn.getmybubble.com/corporate-sites/travelers-logo.svg" className="travelers" loading='lazy' alt="Travelers" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link target="_blank">
									<img src="https://cdn.getmybubble.com/corporate-sites/KW-Specialty-Logo.jpg" className="Pacific" loading='lazy' alt="KW Speciality" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link target="_blank">
									<img src="https://cdn.getmybubble.com/corporate-sites/SES-logo.png" className="Pacific" loading='lazy' alt="SES" />
								</Link>
							</div>

							<div className="col-6 col-md-4 mx-auto text-center mobLogo">
								<Link target="_blank">
									<img src="https://cdn.getmybubble.com/corporate-sites/Delos_Logo_S.png" className="Pacific" loading='lazy' alt="Delos" />
								</Link>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PartnerLogo;
